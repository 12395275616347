import React from "react";

// Customizable Area Start
import { Checkbox, CircularProgress, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Typography } from "@material-ui/core";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import "../assets/css/Categoriessubcategories.web.css";
import { Artimage1, Categorie1, CardImage } from "./assets";
import { withRouter } from "react-router-dom";
const baseURL = require("../../../framework/src/config.js").baseURL
import { IconCheck, IconUnCheck } from "../../assessmenttest/src/Assessmenttest.web";
import Rating from "@material-ui/lab/Rating/Rating";
import TabbyResponseDialog from "../../../components/src/TabbyResponseDialog.web";
// Customizable Area End

import CategoriessubcategoriesController, {
  Props
} from "./CategoriessubcategoriesController";
import SearchScreen from "../../../components/src/SearchScreen.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

export default class Categoriessubcategories extends CategoriessubcategoriesController {
  constructor(props: Props) {
    super(props);
  }

  renderCourses = () => {
    return (
      this.state.filteredata.length ? (
        <div className="home-category-block-content">
          {this.state.filteredata.map(
            (item: { id: string; attributes: { course_image: { url: string; }; course_name: string; information: string; average_rating: number; }; }, index: number) => (
              <div
                data-test-id={`changecategory${index}`}
                className="home-category-content"
                key={index}
                onClick={() =>
                  this.handlecarditemfilter(item.id)
                }
              >
                <img src={item.attributes.course_image ? baseURL + item.attributes.course_image.url : Categorie1} alt="No image available" />
                <div className="home-categorycard-content-des">
                  <div>
                    <h6 className="text-capitalize">{item.attributes.course_name}</h6>
                    <p className="text-capitalize">{item.attributes.information}</p>
                    <div className="home-categorycard-content-review">
                      <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M496,203.3H312.36L256,32,199.64,203.3H16L166.21,308.7,107.71,480,256,373.84,404.29,480,345.68,308.7Z" /></svg>
                      <span>{item.attributes.average_rating.toFixed(1)}</span>
                    </div>
                  </div>
                  <div>
                    <h5 className="text-capitalize">د.إ20</h5>
                  </div>
                </div>

              </div>
            )
          )}
        </div>
      ) :
        <div className="home-category-block-content-norecord norecord">
          <p>No Courses</p>

        </div>
    )
  }

  renderLessonName = () => {
    return (
      <div className={"renderLessonsStyle"} style={{ height: "auto", minHeight: "400px", }}>
        {this.state.sessionList.length ? <div className="home-lesson-heading">
          <h6>Lessons</h6>
        </div> : ""}
        {this.state.sessionList.map((session: { attributes: { title: string; description: string; duration: string | number | Date; }; }, ind: number) => (
          <div className="home-lesson" data-test-id={`testLesson${ind}`} key={ind}>
            <div className="home-lesson-content faqclass">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.3 .5s-12.3-12.2-12.3-20.9V168c0-8.7 4.7-16.7 12.3-20.9z" />
                </svg>
              </span>
              <p>{session.attributes.title ? session.attributes.title : session.attributes.description}</p>
            </div>
            <span>{new Date(session.attributes.duration).getMinutes()} mins</span>
          </div>
        ))}
      </div>
    )
  }

  renderFinalLessonsCountLength = () => {
    let lessonsCount = this.state.filtercarddata.attributes.no_of_lessons
    let LessonsCountLength = this.state.filtercarddata.attributes.no_of_lessons && lessonsCount.toString()
    let FinalLessonsCountLength = this.state.filtercarddata.attributes.no_of_lessons && LessonsCountLength.length
    return (
      <p className="attributes-no_of_lessons">{FinalLessonsCountLength === 1 ? "0" : ""}{this.state.filtercarddata.attributes.no_of_lessons} Sessions</p>
    )
  }

  renderTitle = () => {
    return (
      <>
        {this.state.filtercarddata.attributes.title && <p className="text-capitalize attributestitle">{this.state.filtercarddata.attributes.title}</p>}
        {this.state.filtercarddata.attributes.information && <span className="attributes-information">
          {this.state.filtercarddata.attributes.information}
        </span>}
      </>
    )
  }

  renderImages = () => {
    return (
      <img src={this.state.filtercarddata.attributes.course_image ? baseURL + this.state.filtercarddata.attributes.course_image.url : Artimage1} alt="No image available" />
    )
  }

  render() {


    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        {this.state.isVisibleModal && <TabbyResponseDialog goToPaymentHistory={this.goToPaymentHistory} isVisibleModal={this.state.isVisibleModal} hideModal={this.hideModal} responseFromTabby={this.state.responseFromTabby}/>}
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          title="Browse Course"
        >
          <Paper style={{ width: "100%", overflow: "hidden" }}>
            <div className="content-wrapper">
              <div className="home-search-block">
                <SearchScreen
                  selectHistoryDelete={this.selectHistoryDelete}
                  searchValue={this.state.searchValue}
                  onClickSearchModalOpen={this.onClickSearchModalOpen}
                  onsearchValue={this.onsearchValue}
                  searchHistory={this.state.searchHistory}
                  divRef={this.state.divRef}
                  onClickSearchValue={this.onClickSearchValue}
                  onClickSearchModalClose={this.onClickSearchModalClose}
                  searchHistoryData={this.state.searchHistoryData}
                  clearAllHistory={this.clearAllHistory}
                />
              </div>
              {this.state.isloading ?
                <div className="home-category-block-content-norecord-loader"><CircularProgress style={{ color: '#205e83' }} /></div> :
                <div className="home-page-row">
                  <div className="home-page-col-3">
                    <div className="home-category-block">
                      <h3>Categories</h3>
                      <div className="home-filter-block">
                        <form>
                          <select
                            data-test-id="categorylist"
                            defaultValue={this.state.selectedCategory}
                            onChange={event => this.handlefilter(event.target.value)}
                          >
                            {this.state.categoriesData.map((item: { attributes: { id: string | number | string[] | undefined; name: string; }; }, index: number) => {
                              return (<option key={`categorylistselect${index}`} className="text-capitalize" data-test-id={`categorylistselect${index}`} value={item.attributes.id}>{item.attributes.name}</option>);
                            })}
                          </select>
                        </form>
                        <IconButton className="filter" onClick={this.handleFillterClick} data-test-id="handleFillterClick">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M3 7C3 7.55 3.45 8 4 8H20C20.55 8 21 7.55 21 7C21 6.45 20.55 6 20 6H4C3.45 6 3 6.45 3 7ZM11 18H13C13.55 18 14 17.55 14 17C14 16.45 13.55 16 13 16H11C10.45 16 10 16.45 10 17C10 17.55 10.45 18 11 18ZM17 13H7C6.45 13 6 12.55 6 12C6 11.45 6.45 11 7 11H17C17.55 11 18 11.45 18 12C18 12.55 17.55 13 17 13Z"
                              fill="#37657F"
                            />
                          </svg>
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          data-test-id="handleFillterClose"
                          anchorEl={this.state.anchorEl}
                          keepMounted
                          open={Boolean(this.state.anchorEl)}
                          onClose={this.handleFillterClose}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                          MenuListProps={{
                            style: {
                              padding: 24
                            }
                          }}
                        >
                          <TypographyStyle>Sort by</TypographyStyle>
                          {this.state.sortBy.map((obj: { checked: boolean | undefined; name: string; }, indexs: number) => {
                            return (
                              <StyledMenuItem key={indexs}>
                                <ListItemIcon>
                                  <Checkbox data-test-id={`fillter-data-${indexs}`} className="p-0" color="default" onChange={(event) => this.onchangeSort(event, indexs)} checked={obj.checked} icon={<IconUnCheck />} checkedIcon={<IconCheck />} />
                                </ListItemIcon>
                                <ListItemText secondary={obj.name} />
                              </StyledMenuItem>
                            )
                          })}
                        </Menu>
                      </div>
                      {this.renderCourses()}
                    </div>
                  </div>
                  {!this.state.filtercarddata?.attributes ? "No Course Selected" : <div className="home-page-col-9">
                    <div className="home-lesson-block">
                      <div className="home-content">
                        <div className="home-content-image">
                          {this.renderImages()}
                        </div>
                        <div className="home-item-content">
                          <div className="home-rating-block">
                            <StyledRating name="read-only" value={this.state.filtercarddata.attributes.average_rating} readOnly precision={0.5} />
                            <span>{this.state.filtercarddata.attributes.average_rating?.toFixed(1)}</span>
                          </div>
                          <h3 className="text-capitalize">{this.state.filtercarddata.attributes.course_name}</h3>
                          {this.renderTitle()}
                          <div className="home-user-block">
                            <img
                              src={CardImage}
                              alt="avatar"
                              width={35}
                              height={35}
                            />
                            <p>By {this.state.filtercarddata.attributes.lecturer}</p>
                          </div>
                          <div className="home-info">
                            <div className="home-info-block">
                              {this.state.filtercarddata.attributes.total_duration_of_lessons && (<><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M6.66667 1.6665C5.75 1.6665 5 2.4165 5 3.33317V5.97484C5 6.4165 5.175 6.8415 5.49167 7.15817L8.33333 9.99984L5.48333 12.8498C5.175 13.1665 5 13.5915 5 14.0332V16.6665C5 17.5832 5.75 18.3332 6.66667 18.3332H13.3333C14.25 18.3332 15 17.5832 15 16.6665V14.0332C15 13.5915 14.825 13.1665 14.5167 12.8582L11.6667 9.99984L14.5083 7.1665C14.825 6.84984 15 6.42484 15 5.98317V3.33317C15 2.4165 14.25 1.6665 13.3333 1.6665H6.66667ZM13.3333 13.7498V15.8332C13.3333 16.2915 12.9583 16.6665 12.5 16.6665H7.5C7.04167 16.6665 6.66667 16.2915 6.66667 15.8332V13.7498L10 10.4165L13.3333 13.7498ZM6.66667 4.1665V6.24984L10 9.58317L13.3333 6.24984V4.1665C13.3333 3.70817 12.9583 3.33317 12.5 3.33317H7.5C7.04167 3.33317 6.66667 3.70817 6.66667 4.1665Z"
                                  fill="#37657F"
                                />
                              </svg>
                                <p className="mr-25">{this.state.filtercarddata.attributes.total_duration_of_lessons.toFixed(0)}Minutes</p></>)}
                              {this.state.filtercarddata.attributes.no_of_lessons && (<><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.4997 2.5H2.49967C1.58301 2.5 0.833008 3.25 0.833008 4.16667V14.1667C0.833008 15.0833 1.58301 15.8333 2.49967 15.8333H6.66634V16.6667C6.66634 17.125 7.04134 17.5 7.49967 17.5H12.4997C12.958 17.5 13.333 17.125 13.333 16.6667V15.8333H17.4997C18.4163 15.8333 19.158 15.0833 19.158 14.1667L19.1663 4.16667C19.1663 3.24167 18.4163 2.5 17.4997 2.5ZM16.6663 14.1667H3.33301C2.87467 14.1667 2.49967 13.7917 2.49967 13.3333V5C2.49967 4.54167 2.87467 4.16667 3.33301 4.16667H16.6663C17.1247 4.16667 17.4997 4.54167 17.4997 5V13.3333C17.4997 13.7917 17.1247 14.1667 16.6663 14.1667ZM8.74967 11.7917L12.0663 9.89167C12.6247 9.56667 12.6247 8.76667 12.0663 8.44167L8.74967 6.54167C8.19134 6.225 7.49967 6.625 7.49967 7.26667V11.0667C7.49967 11.7 8.19134 12.1083 8.74967 11.7917Z"
                                  fill="#37657F"
                                />
                              </svg>
                                {this.renderFinalLessonsCountLength()}</>)}
                            </div>
                            {
                              this.state.userRole !== "guest" ?
                                this.state.filtercarddata.attributes.purchased ?
                                  (<div className="home-info-button m-25">
                                    <button className="btn button-green" data-test-id="redirecting" onClick={() => this.onClickRedirct(this.state.filtercarddata.id)}>
                                      Start Course
                                    </button>
                                  </div>) :
                                  (<div className="home-info-button m-25">
                                    <button className="btn button-green" data-test-id="redirecting-other" onClick={() => this.onClickRedirct1(this.state.filtercarddata.id)}>
                                      Enroll Now for at د.إ20
                                    </button>
                                  </div>)
                                : ''
                            }
                          </div>
                        </div>
                      </div>
                      {this.state.filtercarddata.attributes.description && (<><div className="home-item-heading">
                        <h6>
                          About
                        </h6>
                      </div>
                        <div className="home-inner-content">
                          <p>
                            {this.state.filtercarddata.attributes.description}{" "}
                          </p>
                        </div></>)}

                      {this.renderLessonName()}

                    </div>
                  </div>}
                </div>}
            </div>
          </Paper>
        </NavigationMenu>


      </ThemeProvider>
    );
  }
}

const StyledMenuItem = styled(MenuItem)({
  padding: 0,
  "& .MuiListItemText-root": {
    color: "#37657F"
  },
  "& .MuiListItemIcon-root": {
    minWidth: 24,
  },
})

const TypographyStyle = styled(Typography)({
  fontFamily: 'Corbel V2',
  border: 'none',
  fontSize: '24px',
  fontWeight: 700,
  marginBottom: 10
})

const StyledRating = styled(Rating)({
  '& span': {
    fontSize: 18,
    color: 'rgba(0, 0, 0, 0.26)',
  },
  '& .MuiRating-iconFilled': {
    color: '#F59E0B',
  },
  '& .MuiRating-iconHover': {
    color: '#F59E0B',
  }
})






