import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Paper,
  Typography
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import MyCourseController, { Props } from "./MyCourseController";
import { BarChart } from "@material-ui/icons";
const baseURL = require("../../../framework/src/config.js").baseURL


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

// Customizable Area End

export default class MyCourse extends MyCourseController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  resulListtarray = () => {
    if (this.state.serchCourseValue.length) {
      return this.state.allCourseList.filter((x: any) => (x?.attributes?.course_name + x?.attributes?.title).toLowerCase().includes(this.state.serchCourseValue.toLowerCase()))
    } else {
      return this.state.allCourseList
    }
  }

  renderDataList = () => {
    return this.resulListtarray().length ? (
      this.resulListtarray().map((item: any, index: number) => {
        return (
          <Grid item xs={12} key={index}>
            <Box className="mycourses-main back-94A3B8">
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item xs={12} md={8}>
                  <Box className="course-conatin-first">
                    <Box className="image-contant">
                      {item.attributes.course_image == null ? "No image available" : <img
                        src={baseURL + item.attributes.course_image?.url}
                        alt="No image available"
                        width={"100%"}
                        height={"100%"}
                      />}
                    </Box>
                    <Box className="w-full">
                      <Typography className="course-course-by">
                        A Course by Raffles jr.
                      </Typography>
                      <Typography className="course-course-title">
                        {item.attributes.course_name}
                      </Typography>
                      <Typography className="course-course-information">
                        {item.attributes.information.length > 125 ? item.attributes.information.substring(0, 125) + '...' : item.attributes.information}
                      </Typography>
                      <Box className="course-conatin-first-inner margin-1">
                        {item.attributes.total_duration_of_lessons !== null && <Box className="session-contant">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6.66667 1.66406C5.75 1.66406 5 2.41406 5 3.33073V5.9724C5 6.41406 5.175 6.83906 5.49167 7.15573L8.33333 9.9974L5.48333 12.8474C5.175 13.1641 5 13.5891 5 14.0307V16.6641C5 17.5807 5.75 18.3307 6.66667 18.3307H13.3333C14.25 18.3307 15 17.5807 15 16.6641V14.0307C15 13.5891 14.825 13.1641 14.5167 12.8557L11.6667 9.9974L14.5083 7.16406C14.825 6.8474 15 6.4224 15 5.98073V3.33073C15 2.41406 14.25 1.66406 13.3333 1.66406H6.66667ZM13.3333 13.7474V15.8307C13.3333 16.2891 12.9583 16.6641 12.5 16.6641H7.5C7.04167 16.6641 6.66667 16.2891 6.66667 15.8307V13.7474L10 10.4141L13.3333 13.7474ZM6.66667 4.16406V6.2474L10 9.58073L13.3333 6.2474V4.16406C13.3333 3.70573 12.9583 3.33073 12.5 3.33073H7.5C7.04167 3.33073 6.66667 3.70573 6.66667 4.16406Z"
                              fill="#37657F"
                            />
                          </svg>
                          <Typography className="course-course-hours">
                            {item.attributes.total_duration_of_lessons}hours
                          </Typography>
                        </Box>}
                        <Box className="session-contant">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M17.4997 2.5H2.49967C1.58301 2.5 0.833008 3.25 0.833008 4.16667V14.1667C0.833008 15.0833 1.58301 15.8333 2.49967 15.8333H6.66634V16.6667C6.66634 17.125 7.04134 17.5 7.49967 17.5H12.4997C12.958 17.5 13.333 17.125 13.333 16.6667V15.8333H17.4997C18.4163 15.8333 19.158 15.0833 19.158 14.1667L19.1663 4.16667C19.1663 3.24167 18.4163 2.5 17.4997 2.5ZM16.6663 14.1667H3.33301C2.87467 14.1667 2.49967 13.7917 2.49967 13.3333V5C2.49967 4.54167 2.87467 4.16667 3.33301 4.16667H16.6663C17.1247 4.16667 17.4997 4.54167 17.4997 5V13.3333C17.4997 13.7917 17.1247 14.1667 16.6663 14.1667ZM8.74967 11.7917L12.0663 9.89167C12.6247 9.56667 12.6247 8.76667 12.0663 8.44167L8.74967 6.54167C8.19134 6.225 7.49967 6.625 7.49967 7.26667V11.0667C7.49967 11.7 8.19134 12.1083 8.74967 11.7917Z"
                              fill="#37657F"
                            />
                          </svg>
                          <Typography className="course-course-hours">
                            {item.attributes.lessons?.data.length} Sessions
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box className="text-align-end" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Box sx={{ mb: 2 }}>
                      <Button className="edit-btn" data-test-id={`analytics-course-detail-btn-${index}`} onClick={() => this.onClickOpenAnalyticsCourse(item.id)}>
                        Analytics
                        <BarChart />
                      </Button>
                    </Box>
                    <Button className="edit-btn" data-test-id={`edit-course-detail-btn-${index}`} onClick={() => this.onClickEditCourse(item.id)}>
                      Edit
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M21.209 5.63249C21.599 6.02249 21.599 6.65249 21.209 7.04249L19.379 8.87249L15.629 5.12249L17.459 3.29249C17.6459 3.10523 17.8995 3 18.164 3C18.4285 3 18.6822 3.10523 18.869 3.29249L21.209 5.63249ZM3.49902 20.5025V17.4625C3.49902 17.3225 3.54902 17.2025 3.64902 17.1025L14.559 6.19249L18.309 9.94249L7.38902 20.8525C7.29902 20.9525 7.16902 21.0025 7.03902 21.0025H3.99902C3.71902 21.0025 3.49902 20.7825 3.49902 20.5025Z"
                          fill="#37657F"
                        />
                      </svg>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )
      })
    ) : (
      <Typography>No Courses</Typography>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          title={"My courses"}
        >
          <MainContainer>
            <Paper elevation={0} className="paper-container">
              <Box className="container-main">
                <Box>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box className="home-search-block">
                        <input placeholder=" Search here" data-test-id="serchCourseValue-list" value={this.state.serchCourseValue} onChange={(event) => this.serchCourseList(event)} />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="padding-bottom">
                  <Box className="padding-bottom-second">
                    <InputLabel className="main-input-label">
                      My Courses
                    </InputLabel>
                    <Grid container spacing={3} className={this.state.isLoading || !this.resulListtarray().length ? "height-80" : ""}>
                      {this.state.isLoading ? (
                        <Grid item xs={12}>
                          <CircularProgress style={{ color: '#205e83' }} />
                        </Grid>
                      ) : (this.renderDataList())}
                    </Grid>
                  </Box>
                </Box>
                <Box className="btn-container">
                  <Grid container spacing={3} justifyContent="flex-end">
                    <Grid item>
                      <Button className="back-course-lesson-btn" disabled={true}>Back</Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className="save-course-lesson-btn"
                        data-test-id="onClickRedirect-nextpage"
                        onClick={() => this.onClickRedirect()}
                      >
                        Create New Course
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </MainContainer>
        </NavigationMenu>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  "& .paper-container": {
    width: "100%",
    overflow: "hidden"
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Corbel V2"
  },
  "& .container-main": {
    height: "calc(100vh - 110px)",
    padding: "40px",
    background: "#F6F8FA",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: 4
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
      borderRadius: 2,
      background: "#D7E0E5"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#37657F"
    },
    "@media (max-width: 767px)": {
      padding: "15px",
    }
  },
  "& .mycourses-main": {
    background: "#F8FAFC",
    border: "1px solid #94A3B8",
    padding: 24,
    borderRadius: 8
  },
  "& .height-80": {
    height: '80%',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  "& .edit-btn": {
    background: "#CBD5E1",
    color: "#37657F",
    padding: "8px 40px",
    textTransform: "none",
    fontFamily: "Corbel V2",
    borderRadius: 8,
    fontWeight: 700,
    width: "175px",
    "@media (max-width: 502px)": {
      marginTop: 10
    },
    "& svg": {
      marginLeft: 6
    },
    "& span": {
      fontSize: 16
    }
  },
  "& .course-conatin-first": {
    display: "flex",
    alignItems: "center",
    gap: 23,
    "@media (max-width: 500px)": {
      flexDirection: "column"
    }
  },
  "& .course-conatin-first-inner": {
    display: "flex",
    alignItems: "center",
    gap: 23,
    "@media (max-width: 335px)": {
      flexDirection: "column"
    }
  },
  "& .image-contant": {
    maxWidth: 97,
    minWidth: 97,
    height: 97,
    border: "1px solid gray",
    paddingLeft: '5px',
    "@media (max-width: 500px)": {
      width: "100%",
      maxWidth: "100%",
      minWidth: "100%",
    },
    "& img": {
      height: "100%",
      objectFit: "cover",
      borderRadius: 8
    }
  },
  "& .session-contant": {
    display: "flex",
    alignItems: "center",
    gap: 12
  },
  "& .MuiTypography-root": {
    fontFamily: "Corbel V2"
  },
  "& .course-course-by": {
    color: "rgba(0, 0, 0, 0.60)",
    fontSize: 12,
    fontWeight: 700
  },
  "& .course-course-title": {
    color: "#000",
    fontSize: 16,
    fontWeight: 700
  },
  "& .course-course-information": {
    color: "rgba(0, 0, 0, 0.60)",
    fontSize: 14,
    fontWeight: 400
  },
  "& .course-course-hours": {
    color: "rgba(0, 0, 0, 0.60)",
    fontSize: 15,
    fontWeight: 400
  },
  "& .margin-1": {
    marginTop: 5
  },
  "& .main-input-label": {
    color: "#000",
    fontSize: 18,
    fontWeight: 700,
    marginTop: 24,
    marginBottom: 24
  },
  "& .text-align-end": {
    textAlign: "end",
    "@media (max-width: 502px)": {
      textAlign: "center"
    }
  },
  "& .w-full": {
    width: "100%"
  },
  "& .padding-bottom": {
    background: "#F8FAFC",
    border: "1px solid #94A3B8",
    padding: "0 0 0 24px",
    borderRadius: 8,
    height: "400px",
    overflowY: "hidden",
    minHeight: 'calc(100vh - 380px)',
    "&::-webkit-scrollbar": {
      width: 4
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
      borderRadius: 2,
      background: "#D7E0E5"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#37657F"
    }
  },
  "& .padding-bottom-second": {
    overflowY: "auto",
    overflowX: "hidden",
    padding: "0 24px 24px 0",
    height: '100%',
    "&::-webkit-scrollbar": {
      width: 4
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px transparent",
      borderRadius: 2,
      background: "#D7E0E5"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#37657F"
    }
  },
  "& .back-94A3B8": {
    background: "#94a3b836"
  },
  "& .back-course-lesson-btn": {
    background: "transparent",
    color: "#37657F",
    padding: "8px 50px",
    textTransform: "none",
    fontFamily: "Corbel V2",
    fontSize: 16,
    fontWeight: 700,
    width: 180,
    borderRadius: 8,
    border: "1px solid #37657F",
    "& span": {
      fontSize: 16
    }
  },
  "& .save-course-lesson-btn": {
    background: "#37657F",
    color: "#fff",
    padding: "10px 25px",
    textTransform: "none",
    fontFamily: "Corbel V2",
    fontWeight: 700,
    borderRadius: 8,
    "& svg": {
      marginLeft: 6
    },
    "& span": {
      fontSize: 16
    }
  },
  "& .btn-container": {
    margin: "25px 0"
  }
});

// Customizable Area End
