import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Paper,
  Grid,
  withStyles,
  Switch
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import { withRouter } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Emailnotifications2Controller, {
  Props,
  configJSON,
} from "./Emailnotifications2Controller";

export default class Emailnotifications2 extends Emailnotifications2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationMenu id={this.props.id} navigation={this.props.navigation} title="Email Notifications" >
          <Paper elevation={0} style={{ width: "100%", overflow: "hidden" }}>
            <Box component="div" className={classes.maindiv}>
              <Grid container style={{ justifyContent: 'space-between' }}>
                <Grid item sm={12} lg={12}>
                  <Typography className={classes.textClass}>Automatic Reminder</Typography>
                </Grid>
                <Grid item sm={12} lg={12}>
                  <Box className={classes.notificationBox}>
                    <div>
                      <div className={classes.setSwitch}>
                        <Typography component='div'>Notification will be sent through your mail</Typography>
                        <AntSwitch
                          data-testId="switch"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleChange(event)}
                          checked={this.state.checkedC}
                          name="checkedC" />
                      </div>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </NavigationMenu>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  maindiv: {
    padding: '40px',
    background: '#F6F8FA',
    height: 'calc(100vh - 150px)',
    overflow: 'hidden' as const,
  },
  textClass: {
    color: '#000',
    fontFamily: 'Corbel V2',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '-0.198px',
  },
  notificationBox: {
    border: '2px solid #E2E8F0',
    padding: '30px 20px',
    borderRadius: '5px',
    marginTop: '20px'
  },
  setSwitch: {
    '& div': {
      color: '#000',
      fontFamily: 'Corbel V2',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      letterSpacing: '-0.176px'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }
};
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 48,
    height: 28,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: "#37657F",
        // borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
    boxShadow: 'none',
    color: "#fff"
  },
  track: {
    border: '1px solid ${theme.palette.grey[500]}',
    borderRadius: 50 / 2,
    opacity: 1,
    backgroundColor: "#E2E8F0",
  },
  checked: {},
}))(Switch);
//@ts-ignore
export const Emailnotifications2Styles = withRouter(withStyles(webStyles)(
  Emailnotifications2
));
// Customizable Area End
