import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Paper,
  Typography,
  createTheme,
  ThemeProvider,
  IconButton,
  LinearProgress,
  Divider,
  styled,
  Avatar,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import { Chart } from "react-google-charts";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import * as Yup from "yup";
const baseURL = require("../../../framework/src/config.js").baseURL;
const theme = createTheme({
  palette: {
    primary: {
      main: "#589CBC",
      contrastText: "#37657F",
    },
  },
});

const progressBarStyle = {
  width: "100%",
  height: "5px",
  borderRadius: "10px",
  marginBottom: 4,
};

const barChartOptions = {
  chart: {
    title: "Live Sessions Overview",
  },
  hAxis: {
    title: "Dates",
  },
  vAxis: {
    title: "Number of Students",
  },
  legend: { position: "top" },
  isStacked: true,
  bar: {
    groupWidth: "30%",
  },
  colors: ["#37657F", "#589CBC"],
};

const pieChartOptions = {
  chart: {
    title: "Participation Analysis",
  },
  legend: { position: "right" },
  colors: ["#37657F", "#589CBC"],
  tooltip: {
    text: "value",
  },
  pieSliceText: "none",
  backgroundColor: "transparent",
};

// Customizable Area End

import VisualAnalyticsChartController, {
  Props,
} from "./VisualAnalyticsChartController";
import { Field, Formik, FormikProps } from "formik";

const validationSchema = Yup.object({
  date: Yup.date(),
  endTime: Yup.date(),
});

interface InitialValues {
  date: string;
}

export default class VisualAnalyticsChart extends VisualAnalyticsChartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { pieChartData } = this.state;

    const allZero = pieChartData
      .slice(1)
      .every((item: number[]) => item[1] === 0);

    const filteredData = this.state.participantAttendanceData.filter(
      (item: any) =>
        item.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())
    );
    return (
      // Customizable Area Start
      <MainContainer>
        <ThemeProvider theme={theme}>
          <NavigationMenu
            id={this.props.id}
            navigation={this.props.navigation}
            title="Analytics"
          >
            <Paper elevation={0} className="mainPaper">
              <Box component="div" className="pageSize">
                <Box
                  onClick={this.navigateToHomePage}
                  data-test-id="navigate-to-home"
                  className="navigateButton"
                >
                  <svg
                    className="svgBack"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M13 19l-7-7 7-7" />
                    <path d="M6 12h15" />
                  </svg>
                  <Box className="navigateButtonText">Back to Home</Box>
                </Box>

                <Grid container spacing={2} className="mainGrid">
                  <Grid item xs={12} lg={8} className="gridItemHeight99">
                    <Paper className="firstChartPaper">
                      <Box display="flex" justifyContent="space-between" mb={2}>
                        <Box className="firstChartTitle">
                          Live Sessions Overview
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={2}
                        >
                          <Formik
                            onSubmit={(values) => {
                              this.onSubmitFirstChartDate(values);
                            }}
                            validationSchema={validationSchema}
                            initialValues={{
                              date: "",
                              endTime: "",
                            }}
                          >
                            {({ handleSubmit }: FormikProps<InitialValues>) => {
                              return (
                                <form onChange={handleSubmit}>
                                  <Box alignItems="center">
                                    <Box className="time-field">
                                      <Box
                                        component={"div"}
                                        className="position-reletive"
                                      >
                                        <Field
                                          className="input-fiels-create"
                                          type="date"
                                          id="date-first-chart"
                                          name="date"
                                          placeholder="20-08-1990"
                                        />
                                      </Box>
                                      <Typography>to</Typography>
                                      <Box
                                        component={"div"}
                                        className="position-reletive "
                                      >
                                        <Field
                                          className="input-fiels-create"
                                          data-test-id="endTime-first-chart"
                                          type="date"
                                          id="endTime"
                                          name="endTime"
                                          placeholder="Type here"
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </form>
                              );
                            }}
                          </Formik>
                        </Box>
                      </Box>
                      {this.state.firstChartData ? (
                        <Chart
                          chartType="ColumnChart"
                          width="100%"
                          height="400px"
                          data={this.state.firstChartData}
                          options={barChartOptions}
                        />
                      ) : (
                        <Typography
                          align="center"
                          variant="h6"
                          className="firstChartNoData"
                        >
                          No data to display!
                        </Typography>
                      )}
                    </Paper>
                  </Grid>
                  <Grid
                    spacing={1}
                    container
                    item
                    xs={12}
                    lg={4}
                    direction="column"
                    className="gridItemHeight100"
                  >
                    <Grid item xs className="secondChartGrid">
                      <Paper className="secondChartPaper">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={2}
                        >
                          <Formik
                            onSubmit={(values) => {
                              this.onSubmitUserSessionDate(values);
                            }}
                            validationSchema={validationSchema}
                            initialValues={{
                              date: "",
                              endTime: "",
                            }}
                          >
                            {({ handleSubmit }: FormikProps<InitialValues>) => {
                              return (
                                <form onChange={handleSubmit}>
                                  <Box alignItems="center">
                                    <Box className="rightChartTitle">
                                      Number of Sessions:
                                      {
                                        this.state.userSessionData
                                          ?.total_sessions
                                      }
                                    </Box>
                                    <Box className="time-field">
                                      <Box
                                        component={"div"}
                                        className="position-reletive"
                                      >
                                        <Field
                                          className="input-fiels-create"
                                          type="date"
                                          id="date-user-session"
                                          name="date"
                                          placeholder="20-08-1990"
                                        />
                                      </Box>
                                      <Typography>to</Typography>
                                      <Box
                                        component={"div"}
                                        className="position-reletive "
                                      >
                                        <Field
                                          className="input-fiels-create"
                                          data-test-id="endTime-user-session"
                                          type="date"
                                          id="endTime"
                                          name="endTime"
                                          placeholder="Type here"
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                </form>
                              );
                            }}
                          </Formik>
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={2}
                        >
                          <Box className="studentNameText">Student Name</Box>
                          <Box className="studentNameText">
                            Sessions Attended
                          </Box>
                        </Box>
                        <Divider />
                        <Box>
                          {this.state.userSessionData.user_sessions?.map(
                            (student: any) => (
                              <Box>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Box className="studentProgressText">
                                    {student.user_name}
                                  </Box>
                                  <Box className="studentProgressText">
                                    {student.attended_sessions}
                                  </Box>
                                </Box>
                                <LinearProgress
                                  style={progressBarStyle}
                                  variant="determinate"
                                  value={Math.min(
                                    (student.attended_sessions /
                                      student.total_sessions) *
                                      100,
                                    100
                                  )}
                                />
                              </Box>
                            )
                          )}
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid item xs className="secondChartGrid">
                      <Paper className="secondChartPaper">
                        <Formik
                          onSubmit={(values) => {
                            this.onSubmit(values);
                          }}
                          validationSchema={validationSchema}
                          initialValues={{
                            date: "",
                            endTime: "",
                          }}
                        >
                          {({ handleSubmit }: FormikProps<InitialValues>) => {
                            return (
                              <form onChange={handleSubmit}>
                                <Box alignItems="center">
                                  <Box className="rightChartTitle">
                                    Participation Analysis
                                  </Box>
                                  <Box className="time-field">
                                    <Box
                                      component={"div"}
                                      className="position-reletive"
                                    >
                                      <Field
                                        className="input-fiels-create"
                                        type="date"
                                        id="date"
                                        name="date"
                                        placeholder="20-08-1990"
                                      />
                                    </Box>
                                    <Typography>to</Typography>
                                    <Box
                                      component={"div"}
                                      className="position-reletive "
                                    >
                                      <Field
                                        className="input-fiels-create"
                                        data-test-id="endTime"
                                        type="date"
                                        id="endTime"
                                        name="endTime"
                                        placeholder="Type here"
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                                <Box flexGrow={1}>
                                  {allZero ? (
                                    <Typography
                                      align="center"
                                      variant="h6"
                                      className="firstChartNoData"
                                    >
                                      No data to display!
                                    </Typography>
                                  ) : (
                                    <Chart
                                      chartType="PieChart"
                                      width="auto"
                                      height="160px"
                                      data={pieChartData}
                                      options={pieChartOptions}
                                    />
                                  )}
                                </Box>
                              </form>
                            );
                          }}
                        </Formik>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="gridItemHeight100">
                    <Paper className="thirdPaper">
                      <Box className="firstChartTitle thirdPaperPadding">
                        Participant Attendance
                      </Box>

                      <Grid container spacing={2}>
                        <Grid item xs={10}>
                          <Box className="home-search-block thirdPaperPadding">
                            <input
                              placeholder="Search here"
                              data-test-id="serchCourseValue-list"
                              onChange={this.handleSearchChange}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box className="thirdPaperDateRange">
                            <IconButton>
                              <Box className="thirdPaperDateRangeText">
                                Date Range
                              </Box>
                              <DateRange />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>

                      <TableContainer className="tableContainer">
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className="tableCellHeader">
                                Student Name
                              </TableCell>
                              <TableCell className="tableCellHeader">
                                Time
                              </TableCell>
                              <TableCell className="tableCellHeader">
                                Date
                              </TableCell>
                              <TableCell className="tableCellHeader">
                                Attended
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredData.map(
                              (
                                item: {
                                  name: string;
                                  time: string;
                                  date: string;
                                  attended: string;
                                },
                                index: React.Key | undefined
                              ) => (
                                <TableRow key={index}>
                                  <TableCell className="tableCellBorder">
                                    <Box className="tableCellText">
                                      <Avatar
                                        className="avatar"
                                        src="/static/images/avatar/1.jpg"
                                      />
                                      <Box className="participientName">
                                        {item.name}
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell className="tableCell">
                                    {item.time}
                                  </TableCell>
                                  <TableCell className="tableCell">
                                    {item.date}
                                  </TableCell>
                                  <TableCell className="tableCell">
                                    {item.attended}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </NavigationMenu>
        </ThemeProvider>
      </MainContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  "& .mainPaper": {
    width: "100%",
    overflow: "auto",
  },
  "& .pageSize": {
    padding: "0px 36px 36px 36px",
    background: "#F6F8FA",
    height: "calc(100vh - 150px)",
  },
  "& .navigateButton": {
    display: "flex",
    alignItems: "center",
    color: "#37657F",
    cursor: "pointer",
    width: "fit-content",
  },
  "& .svgBack": {
    marginRight: theme.spacing(1),
    width: 24,
    height: 24,
  },
  "& .navigateButtonText": {
    fontFamily: "Corbel V2",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
  },
  "& .mainGrid": {
    height: "100%",
    overflow: "auto",
    padding: "8px",
    background: "#E2E8F0",
    marginTop: "10px",
    borderRadius: "16px",
  },
  "& .gridItemHeight100": {
    height: "100%",
  },
  "& .gridItemHeight99": {
    height: "99%",
  },
  "& .firstChartPaper": {
    padding: 16,
    height: "100%",
    borderRadius: "16px",
    overflow: "auto",
  },
  "& .firstChartTitle": {
    color: "#64748B",
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 700,
  },
  "& .firstChartNoData": {
    color: "#64748B",
    marginTop: "50px",
  },
  "& .secondChartGrid": {
    maxHeight: "50%",
  },
  "& .secondChartPaper": {
    padding: 16,
    borderRadius: "16px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  "& .rightChartTitle": {
    color: "#64748B",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 700,
  },
  "& .studentNameText": {
    color: "#64748B",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
  },
  "& .studentProgressText": {
    marginBottom: 4,
    color: "#0F172A",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
  },
  "& .thirdPaper": {
    borderRadius: "16px",
    margin: "20px auto",
  },
  "& .thirdPaperPadding": {
    padding: "16px",
  },
  "& .thirdPaperDateRange": {
    padding: "16px",
    textAlign: "end",
  },
  "& .thirdPaperDateRangeText": {
    color: "#64748B",
    marginLeft: 8,
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
  },
  "& .tableContainer": {
    height: "50vh",
  },
  "& .tableCellBorder": {
    borderBottom: "none",
    padding: "12px",
  },
  "& .tableCellText": {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "19.32px",
  },
  "& .tableCellHeader": {
    borderBottom: "none",
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Corbel V2",
  },
  "& .tableCell": {
    borderBottom: "none",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.32px",
    fontFamily: "Corbel V2",
  },
  "& .avatar": {
    marginRight: "10px",
  },
  "& .participientName": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "19.32px",
    fontFamily: "Corbel V2",
  },
  "& .time-field": {
    display: "flex",
    alignItems: "center",
    gap: 4,
    "@media (max-width: 520px)": {
      flexDirection: "column",
    },
  },
  "& .position-reletive": {
    position: "relative",
  },
  "& .w-100": {
    width: "100%",
  },
  "& .input-fiels-create": {
    border: "1px solid #CBD5E1",
    background: "#FFFFFF",
    fontFamily: "Corbel V2",
    minWidth: "auto",
    fontSize: "11px",
    minHeight: "20px",
    "&::placeholder": {
      color: "#334155",
      fontFamily: "Corbel V2",
    },
  },
  "& .error-message": {
    fontFamily: "Corbel V2",
    color: "#FF0000",
  },
});
// Customizable Area End
