import React from "react";

// Customizable Area Start
import { Box, Button, CircularProgress, Dialog, DialogContent, Grid, IconButton, InputLabel, LinearProgress, Paper, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import CreateCourseController, {
  Props
} from "./CreateCourseController";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from 'yup';
import Alert from "@material-ui/lab/Alert"
import { downimage } from "./assets";
const baseURL = require("../../../framework/src/config.js").baseURL

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

const validationSchema = Yup.object({
  lessonn: Yup.array().of(
    Yup.object().shape({
      lesson_video: Yup.string().required('Video is required.'),
      lesson_image: Yup.string().required('Attachments is required.'),
      description: Yup.string().required('Description is required.'),
    })
  ),
  total_duration_of_lessons: Yup.string().required('Duration is required.'),
  title: Yup.string().required('Title is required.'),
  category_id: Yup.string().required('Please select course category.'),
  course_type: Yup.string().required('Please select course type.'),
  language: Yup.string().required('Please select language.'),
  information: Yup.string().required('Information is required.'),
  course_name: Yup.string().required('Name is required.'),
  courseImage: Yup.string().required('Course banner/image is required.'),
  description: Yup.string().required('Description is required.'),
  price: Yup.string().matches(/^[-+]?\d+(\.\d{1,2})?$/, 'Please enter valid price.').required('Price is required.'),
  no_of_lessons: Yup.string().required('No of lessons is required.'),
  level: Yup.string().required('Please select level.'),
});
// Customizable Area End

export default class CreateCourse extends CreateCourseController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  fileUploadModalVisible = (setFieldValue: any, valuesLessonn: any) => {
    const Video = "video/mp4,.mp4,video/avi,.avi,video/mpeg,.mpeg,.mpg,video/3gpp,.3gp,.divx,video/x-flv,.flv,video/x-matroska,.mkv,video/quicktime,.mov,audio/ogg,.ogg,video/webm,.webm,video/x-ms-wmv,.wmv"
    const Image = "image/*"
    return (
      <Dialog maxWidth="lg" fullWidth open={this.state.uploadFileModalVisible} data-test-id="edit-course-81">
        <ModalStyle className="uploadModalBlock" data-test-id="edit-course-82">
          <Box className="modalDialog" data-test-id="edit-course-83">
            <Box className="modal-content" data-test-id="edit-course-84">
              <Box className="modalHeader" data-test-id="edit-course-85">
                <Box className="blockHeading" data-test-id="edit-course-86">
                  <h3 data-test-id="edit-course-87">Upload {this.state.modelTitle}</h3>
                  <p data-test-id="edit-course-88">Upload the Lesson videos from your file manager to show facial recognition.</p>
                </Box>
                <Button id='closeFileUploadModalVisible' data-test-id="close-File-Upload-Modal-Visible" onClick={() => { this.ModalClose() }} type="button" className="closeBtn">
                  <span>×</span>
                </Button>
              </Box>
              <Box data-test-id="edit-course-89" className="modalBody">
                <Box className="dargBlock" data-test-id="edit-course-90">
                  <Box className="uploadBtnWrapper" data-test-id="edit-course-91"
                    onClick={this.handleClick}
                  >
                    {/* <input type="file" accept={this.state.modelTitle === "Lesson Video" ? Video : Image} name="profileImg" id="profileImg" data-test-id="profileImg-first" className='profileImg display-none' onChange={(event: any) => this.handleFileChangeProfile(event)} /> */}
                    <button
                      data-test-id="edit-course-92"
                      className="dropButton"
                      onDrop={this.handleDrop}
                      onDragOver={this.handleDragOver}
                      onDragEnter={this.handleDragEnter}
                      onDragLeave={this.handleDragLeave}
                    >
                      {this.state.fileData && this.state.modelTitle !== "Lesson Video" ? (
                        <img data-test-id="image-first" src={URL.createObjectURL(this.state.fileData)} />
                      ) : (
                        <>
                          <svg data-test-id="edit-course-93" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 15.575C11.7167 15.575 11.4793 15.479 11.288 15.287C11.096 15.0957 11 14.8583 11 14.575V7.425L9.125 9.3C8.925 9.5 8.69167 9.6 8.425 9.6C8.15833 9.6 7.91667 9.49167 7.7 9.275C7.5 9.075 7.40433 8.83733 7.413 8.562C7.421 8.28733 7.51667 8.05833 7.7 7.875L11.3 4.275C11.4 4.175 11.5083 4.104 11.625 4.062C11.7417 4.02067 11.8667 4 12 4C12.1333 4 12.2583 4.02067 12.375 4.062C12.4917 4.104 12.6 4.175 12.7 4.275L16.3 7.875C16.5 8.075 16.5957 8.31233 16.587 8.587C16.579 8.86233 16.4833 9.09167 16.3 9.275C16.1 9.475 15.8627 9.579 15.588 9.587C15.3127 9.59567 15.075 9.5 14.875 9.3L13 7.425V14.575C13 14.8583 12.9043 15.0957 12.713 15.287C12.521 15.479 12.2833 15.575 12 15.575ZM6 19.575C5.45 19.575 4.97933 19.3793 4.588 18.988C4.196 18.596 4 18.125 4 17.575V15.575C4 15.2917 4.09567 15.054 4.287 14.862C4.479 14.6707 4.71667 14.575 5 14.575C5.28333 14.575 5.521 14.6707 5.713 14.862C5.90433 15.054 6 15.2917 6 15.575V17.575H18V15.575C18 15.2917 18.096 15.054 18.288 14.862C18.4793 14.6707 18.7167 14.575 19 14.575C19.2833 14.575 19.5207 14.6707 19.712 14.862C19.904 15.054 20 15.2917 20 15.575V17.575C20 18.125 19.8043 18.596 19.413 18.988C19.021 19.3793 18.55 19.575 18 19.575H6Z" fill="#1C1B1F" />
                          </svg>
                          Click to upload or drag &amp; drop<Typography className="sizeText">Maximum file size 100 MB.</Typography>
                        </>
                      )}
                    </button>
                    <input type="file" title="" className='image-uploaded-box' value={this.state.filesUploadFlag}  accept={this.state.modelTitle === "Lesson Video" ? Video : Image} name="myfile" data-test-id="profileImg-second-course" onChange={(event: any) => {
                      this.handleFileChangeProfile(event)
                    }} />
                  </Box>
                </Box>
                {this.state.open ?
                  <Alert action={
                    <IconButton
                      data-test-id="discard_profile_modal_submit-course"
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        this.setState({ open: false })
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                    severity="error">
                    Sorry, an error has occurred while uploading "Raffles Video". Please try again.
                  </Alert> : null
                }
                {this.state.fileData && (
                  <Box className="process-block" data-test-id="edit-course-94">
                    <LinearProgress variant="determinate" value={this.state.progressUpload} className='process-bar-clr' data-test-id="edit-course-95"/>
                    <Box className="process-info" data-test-id="edit-course-96">
                      <Box className="process-info-image" data-test-id="edit-course-97">
                        {this.state.modelTitle === "Lesson Video" ? (
                          <svg data-test-id="edit-course-98" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6.92773 6.82172V17.1817C6.92773 17.9717 7.79773 18.4517 8.46773 18.0217L16.6077 12.8417C17.2277 12.4517 17.2277 11.5517 16.6077 11.1517L8.46773 5.98172C7.79773 5.55172 6.92773 6.03172 6.92773 6.82172Z" fill="#37657F" />
                          </svg>
                        ) : (
                          <img src={URL.createObjectURL(this.state.fileData)} data-test-id="edit-course-99"/>
                        )}
                      </Box>
                      <Box className="process-info-content" data-test-id="edit-course-100">
                        <h6 data-test-id="edit-course-101">{this.state.fileData.name}</h6>
                        <span data-test-id="edit-course-102">{this.state.progressUpload}% uploaded</span>
                      </Box>
                      <Box id='processBlockClose' data-test-id="processBlockClose" onClick={() => { this.setState({ imagesProfile: {}, fileData: null, filesUploadFlag:"" }) }} className="process-block-close">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className="modalFooter" data-test-id="edit-course-103">
              <Button id='discardId' data-test-id="discardId-edit-course" onClick={() => { this.ModalClose() }} type="button" variant="outlined" className="discardBtn">Discard</Button>
              <Button id='discardProfileModalSubmit-edit-course' type="button" data-test-id="discardProfileModalSubmit" className="uploadBtn" variant="outlined" onClick={() => { this.editProfileDetailsImages(setFieldValue, valuesLessonn) }}>{"Upload now"}</Button>
            </Box>
          </Box>
        </ModalStyle>
      </Dialog>)
  }

  videoRender = (lesson: any, index: number) => {
    return (
      <Box
        data-test-id="edit-course-104"
        component={"div"}
        className='uploadBtnWrapper'
      >
        <button type="button" className={lesson.lesson_video.name || lesson.lesson_video !== "" ? "uploadBtn-upload" : 'uploadBtn'} data-test-id="uploadBtn" onClick={() => this.onClickOpenModel("Lesson Video", { name: "lesson_video", index: index })}>

          {lesson.lesson_video !== "" ? (
            <Box className="image-upload-after-main" data-test-id="edit-course-105">
              <Box className="image-upload-after" data-test-id="edit-course-106">
                <svg data-test-id="edit-course-107" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M6.92773 6.82172V17.1817C6.92773 17.9717 7.79773 18.4517 8.46773 18.0217L16.6077 12.8417C17.2277 12.4517 17.2277 11.5517 16.6077 11.1517L8.46773 5.98172C7.79773 5.55172 6.92773 6.03172 6.92773 6.82172Z" fill="#37657F" />
                </svg>
              </Box>
              <Box data-test-id="edit-course-108">
                <Typography data-test-id="edit-course-109" className="image-upload-maintitle">{lesson.lesson_video.name ? lesson.lesson_video.name : this.renderFileName(lesson.lesson_video)}</Typography>
                <Typography className="image-upload-size" data-test-id="edit-course-110">{  (lesson.lesson_video.size  / (1024 * 1024)).toFixed(2) } MB</Typography>
              </Box>
            </Box>
          ) : (
            <>
              Type Lesson Name
              <svg
                data-test-id="edit-course-111"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 15.575C11.7167 15.575 11.4793 15.479 11.288 15.287C11.096 15.0957 11 14.8583 11 14.575V7.425L9.125 9.3C8.925 9.5 8.69167 9.6 8.425 9.6C8.15833 9.6 7.91667 9.49167 7.7 9.275C7.5 9.075 7.40433 8.83733 7.413 8.562C7.421 8.28733 7.51667 8.05833 7.7 7.875L11.3 4.275C11.4 4.175 11.5083 4.104 11.625 4.062C11.7417 4.02067 11.8667 4 12 4C12.1333 4 12.2583 4.02067 12.375 4.062C12.4917 4.104 12.6 4.175 12.7 4.275L16.3 7.875C16.5 8.075 16.5957 8.31233 16.587 8.587C16.579 8.86233 16.4833 9.09167 16.3 9.275C16.1 9.475 15.8627 9.579 15.588 9.587C15.3127 9.59567 15.075 9.5 14.875 9.3L13 7.425V14.575C13 14.8583 12.9043 15.0957 12.713 15.287C12.521 15.479 12.2833 15.575 12 15.575ZM6 19.575C5.45 19.575 4.97933 19.3793 4.588 18.988C4.196 18.596 4 18.125 4 17.575V15.575C4 15.2917 4.09567 15.054 4.287 14.862C4.479 14.6707 4.71667 14.575 5 14.575C5.28333 14.575 5.521 14.6707 5.713 14.862C5.90433 15.054 6 15.2917 6 15.575V17.575H18V15.575C18 15.2917 18.096 15.054 18.288 14.862C18.4793 14.6707 18.7167 14.575 19 14.575C19.2833 14.575 19.5207 14.6707 19.712 14.862C19.904 15.054 20 15.2917 20 15.575V17.575C20 18.125 19.8043 18.596 19.413 18.988C19.021 19.3793 18.55 19.575 18 19.575H6Z"
                  fill="#37657F"
                />
              </svg>
            </>
          )}
        </button>
        <ErrorMessage data-test-id="edit-course-112" name={`lessonn[${index}].lesson_video`} component="div" className="error-message" />
      </Box>
    )
  }

  imageRender = (lesson: any, index: number) => {
    return (
      <Box
        data-test-id="edit-course-113"
        component={"div"}
        className='uploadBtnWrapper'
      >
        <button type="button" className={lesson.lesson_image.name || lesson.lesson_image !== "" ? "uploadBtn-upload" : 'uploadBtn'} data-test-id="uploadBtn-upload" onClick={() => this.onClickOpenModel("Lesson Attachments", { name: "lesson_image", index: index })}>
          {lesson.lesson_image !== "" ? (
            <Box className="image-upload-after-main" data-test-id="edit-course-114">
              <Box className="image-upload-after" data-test-id="edit-course-115">
                <svg data-test-id="edit-course-116" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M16 6.74917V17.3292C16 19.4192 14.47 21.2792 12.39 21.4792C10 21.7092 8 19.8392 8 17.4992V5.13917C8 3.82917 8.94 2.63917 10.24 2.50917C11.74 2.35917 13 3.52917 13 4.99917V15.4992C13 16.0492 12.55 16.4992 12 16.4992C11.45 16.4992 11 16.0492 11 15.4992V6.74917C11 6.33917 10.66 5.99917 10.25 5.99917C9.84 5.99917 9.5 6.33917 9.5 6.74917V15.3592C9.5 16.6692 10.44 17.8592 11.74 17.9892C13.24 18.1392 14.5 16.9692 14.5 15.4992V5.16917C14.5 3.07917 12.97 1.21917 10.89 1.01917C8.51 0.789169 6.5 2.65917 6.5 4.99917V17.2692C6.5 20.1392 8.6 22.7092 11.46 22.9792C14.75 23.2792 17.5 20.7192 17.5 17.4992V6.74917C17.5 6.33917 17.16 5.99917 16.75 5.99917C16.34 5.99917 16 6.33917 16 6.74917Z" fill="#37657F" />
                </svg>
              </Box>
              <Box data-test-id="edit-course-117">
                <Typography data-test-id="edit-course-118" className="image-upload-maintitle">{lesson.lesson_image.name ? lesson.lesson_image.name : this.renderFileName(lesson.lesson_image)}</Typography>
                <Typography data-test-id="edit-course-119" className="image-upload-size">2369756</Typography>
              </Box>
            </Box>
          ) : (
            <>
              Type Lesson Name
              <svg
                data-test-id="edit-course-118"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 15.575C11.7167 15.575 11.4793 15.479 11.288 15.287C11.096 15.0957 11 14.8583 11 14.575V7.425L9.125 9.3C8.925 9.5 8.69167 9.6 8.425 9.6C8.15833 9.6 7.91667 9.49167 7.7 9.275C7.5 9.075 7.40433 8.83733 7.413 8.562C7.421 8.28733 7.51667 8.05833 7.7 7.875L11.3 4.275C11.4 4.175 11.5083 4.104 11.625 4.062C11.7417 4.02067 11.8667 4 12 4C12.1333 4 12.2583 4.02067 12.375 4.062C12.4917 4.104 12.6 4.175 12.7 4.275L16.3 7.875C16.5 8.075 16.5957 8.31233 16.587 8.587C16.579 8.86233 16.4833 9.09167 16.3 9.275C16.1 9.475 15.8627 9.579 15.588 9.587C15.3127 9.59567 15.075 9.5 14.875 9.3L13 7.425V14.575C13 14.8583 12.9043 15.0957 12.713 15.287C12.521 15.479 12.2833 15.575 12 15.575ZM6 19.575C5.45 19.575 4.97933 19.3793 4.588 18.988C4.196 18.596 4 18.125 4 17.575V15.575C4 15.2917 4.09567 15.054 4.287 14.862C4.479 14.6707 4.71667 14.575 5 14.575C5.28333 14.575 5.521 14.6707 5.713 14.862C5.90433 15.054 6 15.2917 6 15.575V17.575H18V15.575C18 15.2917 18.096 15.054 18.288 14.862C18.4793 14.6707 18.7167 14.575 19 14.575C19.2833 14.575 19.5207 14.6707 19.712 14.862C19.904 15.054 20 15.2917 20 15.575V17.575C20 18.125 19.8043 18.596 19.413 18.988C19.021 19.3793 18.55 19.575 18 19.575H6Z"
                  fill="#37657F"
                />
              </svg>
            </>
          )}
        </button>
        <ErrorMessage data-test-id="edit-course-119" name={`lessonn[${index}].lesson_image`} component="div" className="error-message" />
      </Box>
    )

  }

  renderFileName = (URL: string) => {
    const aray = URL && URL.split("/");
    return aray && aray[aray?.length - 1]
  }

  renderCourseName = () => {
    let courseName = this.state.courseDetails.course_name ? this.state.courseDetails.course_name : ""
    return courseName
  }

  DeleteModal = () => {
    return(
      <ModalStyleMainContainer
      maxWidth={"sm"}
      fullWidth
      open={this.state.onDeleteOpen}
    >
      <DialogContent >
        <ModalStyleMain>
          <Box className="first-container" data-test-id="delete-model-1">
            <Grid container justifyContent="flex-end" data-test-id="delete-model-2">
              <Grid item data-test-id="delete-model-2">
                <Box className="close-button-session" data-test-id="close-button-delete-model" onClick={() => this.onClickCloseDelete()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <mask id="mask0_7217_3205"  maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                    <rect y="0.5" width="24" height="24" fill="#D9D9D9"/>
                    </mask>
                    <g mask="url(#mask0_7217_3205)">
                    <path d="M6.4 19.5L5 18.1L10.6 12.5L5 6.9L6.4 5.5L12 11.1L17.6 5.5L19 6.9L13.4 12.5L19 18.1L17.6 19.5L12 13.9L6.4 19.5Z" fill="#1C1B1F"/>
                    </g>
                  </svg>
                </Box>
              </Grid>
            </Grid>
            <Box data-test-id="delete-model-7">
              <Box className="Delete-icon-container" data-test-id="delete-model-3">
                <svg data-test-id="delete-model-4" xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M16.0226 4.06417L16.7917 4.83333H19.5001C20.0959 4.83333 20.5834 5.32083 20.5834 5.91667C20.5834 6.5125 20.0959 7 19.5001 7H6.50008C5.90425 7 5.41675 6.5125 5.41675 5.91667C5.41675 5.32083 5.90425 4.83333 6.50008 4.83333H9.20841L9.97758 4.06417C10.1726 3.86917 10.4542 3.75 10.7359 3.75H15.2642C15.5459 3.75 15.8276 3.86917 16.0226 4.06417ZM6.50008 21.0833C6.50008 22.275 7.47508 23.25 8.66675 23.25H17.3334C18.5251 23.25 19.5001 22.275 19.5001 21.0833V10.25C19.5001 9.05833 18.5251 8.08333 17.3334 8.08333H8.66675C7.47508 8.08333 6.50008 9.05833 6.50008 10.25V21.0833ZM9.75008 10.25H16.2501C16.8459 10.25 17.3334 10.7375 17.3334 11.3333V20C17.3334 20.5958 16.8459 21.0833 16.2501 21.0833H9.75008C9.15425 21.0833 8.66675 20.5958 8.66675 20V11.3333C8.66675 10.7375 9.15425 10.25 9.75008 10.25Z" fill="#37657F"/>
                </svg>
              </Box>
              <Typography className="title-one" data-test-id="delete-model-5">Course Delete</Typography>
              <Typography className="title-two" data-test-id="delete-model-6">Are you sure want to delete this Course.</Typography>  
            </Box>
          </Box>
          <Box className="second-container" data-test-id="delete-model-8">
            <Button type="button" data-test-id="cancel-delete-button-delete-model" onClick={() => this.onClickCloseDelete()} className="cancel-delete-button">Cancel Delete</Button>
            <Button type="button" data-test-id="delete-session-button-delete-model" onClick={() => this.onDelete()} className="delete-session-button">Delete Course</Button>
          </Box>
        </ModalStyleMain>
      </DialogContent>
    </ModalStyleMainContainer>
    )
    
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme} data-test-id="edit-course-120">
        <NavigationMenu
          id={this.props.id}
          navigation={this.props.navigation}
          title={this.renderCourseName()}
        >
          <MainContainer data-test-id="edit-course-121">
            <Paper elevation={0} className="paper-container" data-test-id="edit-course-122">
              {this.state.isLoading ? (
                <Box component={"div"} className="loader-container" data-test-id="assessment-test-loader">
                  <CircularProgress style={{ color: '#205e83' }} />
                </Box>
              ) : (

                <Formik
                  initialValues={{
                    courseImage: this.state.courseDetails.course_image?.url,
                    course_name: this.state.courseDetails.course_name,
                    title: this.state.courseDetails.title,
                    information: this.state.courseDetails.information,
                    description: this.state.courseDetails.description,
                    price: this.state.courseDetails.price,
                    level: this.state.courseDetails.level,
                    course_type: this.state.courseDetails.course_type,
                    no_of_lessons: this.state.courseDetails.no_of_lessons,
                    total_duration_of_lessons: this.state.courseDetails.total_duration_of_lessons,
                    category_id: this.state.courseDetails.category_id,
                    language: this.state.courseDetails.language,
                    lessonn: this.state.courseDetails?.lessons?.data || [
                      {
                        lesson_video: "",
                        lesson_image: "",
                        description: "",
                      }
                    ],
                  }}
                  onSubmit={(values) => {
                    this.onUpdate(values)
                  }}
                  validationSchema={validationSchema}
                >

                  {({ handleSubmit, setFieldValue, values}: any) => {
                    return (
                      <form onSubmit={handleSubmit} data-test-id="edit-course-1">
                        <Box className="container-main" data-test-id="edit-course-2">
                          <Box className="marginbottom" data-test-id="edit-course-3">
                            <Typography className="from-main-title" data-test-id="edit-course-4">Course Banner/Image</Typography>
                            <Box className="from-first-container" data-test-id="edit-course-5">
                              <Grid container className="from-first-container-grid" data-test-id="edit-course-6">
                                <Grid item data-test-id="edit-course-7">
                                  <Box className="image-container-main" data-test-id="edit-course-8">
                                    <Box className="image-container" data-test-id="edit-course-9">
                                      <img data-test-id="edit-course-10" src={values.courseImage?.name ? URL.createObjectURL(values.courseImage) : baseURL + values.courseImage} />
                                    </Box>
                                    <Box data-test-id="edit-course-11">
                                      <Typography data-test-id="edit-course-12" className="from-main-title-first">{values.title ? values.title : "Title"}</Typography>
                                      <Typography data-test-id="edit-course-13" className="from-main-title-second">{this.state.categoryName ? this.state.categoryName : "Course Category"}</Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item data-test-id="edit-course-14">
                                  <Box className="upload-icon-container" data-test-id="edit-course-15">
                                    <input
                                      className="courseImage"
                                      accept="image/*"
                                      type="file"
                                      name="courseImage"
                                      id="courseImage"
                                      data-test-id="uploadField-edit-course"
                                      onChange={(event: any) => {
                                        setFieldValue(
                                          "courseImage",
                                          event.currentTarget.files[0]
                                        );
                                      }}
                                      title=""
                                    />
                                    <svg data-test-id="edit-course-16" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                      <path d="M2 16C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V11H2V14H14V11H16V14C16 14.55 15.8042 15.0208 15.4125 15.4125C15.0208 15.8042 14.55 16 14 16H2ZM7 12V3.85L4.4 6.45L3 5L8 0L13 5L11.6 6.45L9 3.85V12H7Z" fill="#37657F" />
                                    </svg>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            <ErrorMessage data-test-id="edit-course-17" name="courseImage" component="div" className="error-message" />
                          </Box>


                          <Box className="marginbottom" data-test-id="edit-course-18">
                            <Typography className="from-main-title" data-test-id="edit-course-19">Information</Typography>
                            <Box className="from-first-container" data-test-id="edit-course-20">
                              <Grid container spacing={2} data-test-id="edit-course-21">
                                <Grid item xs={12} data-test-id="edit-course-22">
                                  <InputLabel data-test-id="edit-course-23" className="main-input-label">Course Name*</InputLabel>
                                  <Field data-test-id="edit-course-24" className="input-fiels-create" type="text" id="course_name" name="course_name" placeholder="Type here" />
                                  <ErrorMessage data-test-id="edit-course-25" name="course_name" component="div" className="error-message" />
                                </Grid>
                                <Grid item xs={12} data-test-id="edit-course-26">
                                  <InputLabel data-test-id="edit-course-27" className="main-input-label">Title*</InputLabel>
                                  <Field className="input-fiels-create" data-test-id="edit-course-28" type="text" id="title" name="title" placeholder="Type here" />
                                  <ErrorMessage name="title" component="div" className="error-message" data-test-id="edit-course-29"/>
                                </Grid>
                                <Grid item xs={12}>
                                  <InputLabel className="main-input-label" data-test-id="edit-course-30">Information*</InputLabel>
                                  <Field className="input-fiels-create" type="text" id="information" data-test-id="edit-course-31" name="information" placeholder="Type here" />
                                  <ErrorMessage name="information" component="div" className="error-message" data-test-id="edit-course-32"/>
                                </Grid>
                                <Grid item xs={12} data-test-id="edit-course-33">
                                  <InputLabel className="main-input-label" data-test-id="edit-course-34">Course Category*</InputLabel>
                                  <Box className="select-course-category" data-test-id="edit-course-35">
                                    <Field data-test-id="select-course-category-edit-course" id="select-course-category" as="select" name="category_id"
                                      onChange={(event: any) => {
                                        const value = event.target.value
                                        setFieldValue("category_id", value)
                                        this.onChangeCourseSelect(value)
                                      }
                                      }>
                                      <option className="text-capitalize" disabled value="">Select Course Category</option>
                                      {this.state.categoriesList.map((item: any, index: number) => {
                                        return (<option key={`categorylistselect${index}`} className="text-capitalize" data-test-id={`categorylistselect${index}-edit-course`} value={item.attributes.id}>{item.attributes.name}</option>);
                                      })}
                                    </Field>
                                  </Box>
                                  <ErrorMessage data-test-id="edit-course-36" name="category_id" component="div" className="error-message" />
                                </Grid>
                                <Grid item xs={12} data-test-id="edit-course-37">
                                  <InputLabel data-test-id="edit-course-38" className="main-input-label">Language*</InputLabel>
                                  <Box className="select-course-category">
                                    <Field data-test-id="selectgender-edit-course" id="select-course-category" as="select" name="language" >
                                      <option className="text-capitalize" value="" disabled>Select Language</option>
                                      <option className="text-capitalize" value="English">English</option>
                                      <option className="text-capitalize" value="Mandarin">Mandarin</option>
                                      <option className="text-capitalize" value="Hindi">Hindi</option>
                                      <option className="text-capitalize" value="German">German</option>
                                      <option className="text-capitalize" value="Italian">Italian</option>
                                    </Field>
                                  </Box>
                                  <ErrorMessage data-test-id="edit-course-39" name="language" component="div" className="error-message" />
                                </Grid>
                                <Grid item xs={12} data-test-id="edit-course-40">
                                  <InputLabel className="main-input-label" data-test-id="edit-course-41">Description*</InputLabel>
                                  <Field rows="4" className="input-fiels-create-textarea" cols="50" data-test-id="edit-course-42" as="textarea" id="description" name="description" placeholder="Placeholder" />
                                  <ErrorMessage name="description" component="div" className="error-message" data-test-id="edit-course-43"/>
                                </Grid>
                                <Grid item xs={12} data-test-id="edit-course-44">
                                  <InputLabel className="main-input-label" data-test-id="edit-course-45">Price*</InputLabel>
                                  <Box className="price-field" data-test-id="edit-course-46">
                                    <Field className="input-fiels-create" data-test-id="edit-course-47" type="number" id="price" name="price" placeholder="Type Price" />
                                    <Box className="price-icon" data-test-id="edit-course-48">
                                      <svg data-test-id="edit-course-49" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12.3 10.9C10.03 10.31 9.3 9.7 9.3 8.75C9.3 7.66 10.31 6.9 12 6.9C13.42 6.9 14.13 7.44 14.39 8.3C14.51 8.7 14.84 9 15.26 9H15.56C16.22 9 16.69 8.35 16.46 7.73C16.04 6.55 15.06 5.57 13.5 5.19V4.5C13.5 3.67 12.83 3 12 3C11.17 3 10.5 3.67 10.5 4.5V5.16C8.56 5.58 7 6.84 7 8.77C7 11.08 8.91 12.23 11.7 12.9C14.2 13.5 14.7 14.38 14.7 15.31C14.7 16 14.21 17.1 12 17.1C10.35 17.1 9.5 16.51 9.17 15.67C9.02 15.28 8.68 15 8.27 15H7.99C7.32 15 6.85 15.68 7.1 16.3C7.67 17.69 9 18.51 10.5 18.83V19.5C10.5 20.33 11.17 21 12 21C12.83 21 13.5 20.33 13.5 19.5V18.85C15.45 18.48 17 17.35 17 15.3C17 12.46 14.57 11.49 12.3 10.9Z" fill="#0F172A" />
                                      </svg>
                                    </Box>
                                  </Box>
                                  <ErrorMessage data-test-id="edit-course-50" name="price" component="div" className="error-message" />
                                </Grid>
                                <Grid item xs={12} data-test-id="edit-course-51">
                                  <InputLabel data-test-id="edit-course-52" className="main-input-label">Level*</InputLabel>
                                  <Box className="select-course-category" data-test-id="edit-course-53">
                                    <Field data-test-id="selectgender-edit-course" id="select-course-category" as="select" name="level" >
                                      <option className="text-capitalize" value="" disabled>Select Level</option>
                                      <option className="text-capitalize" value="beginner">Beginner</option>
                                      <option className="text-capitalize" value="intermediate">Intermediate</option>
                                      <option className="text-capitalize" value="advanced">Advanced</option>
                                    </Field>
                                  </Box>
                                  <ErrorMessage data-test-id="edit-course-54" name="level" component="div" className="error-message" />
                                </Grid>
                                <Grid item xs={12} data-test-id="edit-course-55">
                                  <InputLabel data-test-id="edit-course-56" className="main-input-label">Course Type*</InputLabel>
                                  <Box className="select-course-category" data-test-id="edit-course-57">
                                    <Field data-test-id="selectgender" id="select-course-category" as="select" name="course_type" >
                                      <option className="text-capitalize" value="" disabled>Select Level</option>
                                      <option className="text-capitalize" value="live_session">Live session</option>
                                      <option className="text-capitalize" value="online_courses">Online courses</option>
                                      <option className="text-capitalize" value="on_campus_workshop">On campus workshop</option>
                                    </Field>
                                  </Box>
                                  <ErrorMessage data-test-id="edit-course-58" name="course_type" component="div" className="error-message" />
                                </Grid>
                                <Grid item xs={12} data-test-id="edit-course-59">
                                  <InputLabel data-test-id="edit-course-60" className="main-input-label">No of Lessons*</InputLabel>
                                  <Field className="input-fiels-create" data-test-id="edit-course-61" type="number" id="no_of_lessons" name="no_of_lessons" placeholder="Type" />
                                  <ErrorMessage name="no_of_lessons" data-test-id="edit-course-62" component="div" className="error-message" />
                                </Grid>
                                <Grid item xs={12} data-test-id="edit-course-63">
                                  <InputLabel className="main-input-label" data-test-id="edit-course-64">Total Duration of Lessons*</InputLabel>
                                  <Field className="input-fiels-create" type="number" id="total_duration_of_lessons" data-test-id="edit-course-65" name="total_duration_of_lessons" placeholder="Type" />
                                  <ErrorMessage name="total_duration_of_lessons" component="div" className="error-message" data-test-id="edit-course-66"/>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>

                            <Box className="marginbottom-40" data-test-id="edit-course-67">
                              <Typography className="from-main-title" data-test-id="edit-course-68">Lessons</Typography>
                              {values.lessonn.map((lesson: any, index: number) => (
                                  <Box className="from-first-container" key={index} data-test-id="edit-course-69">
                                    <Grid container spacing={2} justifyContent="flex-end" data-test-id="edit-course-70">
                                      <Grid item xs={12} md={6} data-test-id="edit-course-71">
                                        <InputLabel className="main-input-label" data-test-id="edit-course-72">Lesson Video</InputLabel>
                                        {this.videoRender(lesson, index)}
                                      </Grid>

                                      <Grid item xs={12} md={6} data-test-id="edit-course-73">
                                        <InputLabel className="main-input-label" data-test-id="edit-course-74">Lesson Attachments</InputLabel>
                                        {this.imageRender(lesson, index)}
                                      </Grid>

                                      <Grid item xs={12} data-test-id="edit-course-75">
                                        <InputLabel className="main-input-label" data-test-id="edit-course-76">Lesson Description*</InputLabel>
                                        <Field rows="4" className="input-fiels-create-textarea" cols="50" data-test-id="edit-course-77" as="textarea" id={`description_${index}`} name={`lessonn[${index}].description`} placeholder="Placeholder" />
                                        <ErrorMessage name={`lessonn[${index}].description`} component="div" className="error-message" data-test-id="edit-course-78"/>
                                      </Grid>

                                      <Grid item data-test-id="edit-course-79">
                                        <Button className="remove-btn" data-test-id="remove-btn" disabled={values.lessonn.length >= 2 ? false : true} type="button" onClick={() => this.handleRemoveLesson(setFieldValue, values, index, lesson?.id)}>
                                          Remove
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M14.79 3.29L15.5 4H18C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6H6C5.45 6 5 5.55 5 5C5 4.45 5.45 4 6 4H8.5L9.21 3.29C9.39 3.11 9.65 3 9.91 3H14.09C14.35 3 14.61 3.11 14.79 3.29ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9Z" fill="#DC2626" />
                                          </svg>
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Box>
                              ))}
                            </Box>

                          {this.fileUploadModalVisible(setFieldValue, values.lessonn)}
                          <Box className="marginbottom-40" data-test-id="edit-course-80">
                            <Grid container justifyContent="flex-end" data-test-id="edit-course-81">
                              <Grid item data-test-id="edit-course-82">
                                <Button className="add-lesson-btn" data-test-id="add-lesson-btn" onClick={() => this.handleAddLesson(setFieldValue, values)}>
                                  Add Lesson
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="white" />
                                  </svg>
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>

                          <Box className="marginbottom" data-test-id="edit-course-83">
                            <Grid container justifyContent="flex-end" spacing={3} data-test-id="edit-course-84">
                              <Grid item data-test-id="edit-course-125">
                                <Button type="button" className="delete-course-btn" data-test-id="delete-course-btn" onClick={() => this.onClickOpenDelete()}>
                                  Delete
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.79 3.29L15.5 4H18C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6H6C5.45 6 5 5.55 5 5C5 4.45 5.45 4 6 4H8.5L9.21 3.29C9.39 3.11 9.65 3 9.91 3H14.09C14.35 3 14.61 3.11 14.79 3.29ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9Z" fill="#DC2626"/>
                                  </svg>
                                </Button>
                              </Grid>
                              <Grid item data-test-id="edit-course-85">
                                <Button className="back-course-lesson-btn" data-test-id="go-back-save-course" onClick={() => this.onClickGoBack()}>
                                  Back
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button type="submit" data-test-id="save-course" className="save-course-lesson-btn" disabled={this.state.submitFlag}>
                                  Save Course
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </form>
                    )
                  }}

                </Formik>
              )}
              {this.DeleteModal()}
            </Paper>
          </MainContainer>
        </NavigationMenu>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  "& .loader-container":{overflowY:'auto',height:'calc(100vh - 240px)',display:'flex',alignItems:'center',justifyContent:'center',background:'#F6F8FA',},
  "& .courseImage": { maxWidth: 37,minWidth: 37,minHeight: 37,maxHeight: 37,position: 'absolute',top: 0,left: 0,opacity: 0},
  "& .save-course-lesson-btn":{padding:"10px 50px",width: 180,borderRadius: 8},
  "& .image-upload-after":{height:55,width:55,borderRadius:8,display:'flex',justifyContent:'center',alignItems:'center',background:"#F1F5F9"},
  "& .image-upload-after-main": {display: "flex", alignItems: "center", gap: 20},
  "& .image-upload-maintitle":{fontFamily:"Corbel V2",fontWeight:400,color:"#0F172A",fontSize:16,},
  "& .image-upload-size":{fontFamily:"Corbel V2",fontWeight:400,color:"#37657F",fontSize:16,},
  "& .marginbottom": {marginBottom: 20},
  "& .marginbottom-40": {marginBottom: 40},
  "& .delete-course-btn":{background:"transparent",color:"#DC2626",padding:"8px 0",textTransform:'none',fontFamily:"Corbel V2",width:'180px',fontSize:16,fontWeight:700,border:"1px solid #DC2626",borderRadius: 8,"& span":{fontSize:16,},"& svg":{marginLeft:6,}},
  "& .remove-btn":{background:"transparent",borderRadius: 8,color:"#DC2626",padding:"8px 16px",textTransform:'none',fontFamily:"Corbel V2",fontWeight:700,border:"1px solid #DC2626","& svg":{marginLeft:6,},"& span":{fontSize:16,}},
  "& .select-course-category":{background:"#FFFFFF",borderRadius:8},
  "& #select-course-category":{appearance:"none",background:`url("${downimage}") no-repeat 99% center`},
  "& .paper-container":{width:"100%",overflow:"hidden","& form":{margin:0,}},
  "& .container-main":{height:'calc(100vh - 110px)',padding:'40px',background:'#F6F8FA',overflowX:"hidden","&::-webkit-scrollbar":{width:4},"&::-webkit-scrollbar-track":{boxShadow:"inset 0 0 5px transparent",borderRadius:2,background:"#D7E0E5"},"&::-webkit-scrollbar-thumb":{background:"#37657F"}},
  "& .from-main-title":{color:"#000",fontFamily:"Corbel V2",fontSize:18,fontWeight:700,marginBottom:10},
  "& .back-course-lesson-btn":{background:"transparent",color:"#37657F",padding:"8px 70px",textTransform:'none',fontFamily:"Corbel V2",fontSize:16,fontWeight:700,border:"1px solid #37657F",borderRadius: 8,"& span":{fontSize:16,}},
  "& .from-first-container":{background:"#E2E8F0",borderRadius:8,padding:40,marginBottom:24},
  "& .from-first-container-grid":{alignItems:'center',justifyContent:'space-between'},
  "& .image-container-main":{display:"flex",alignItems:'center'},
  "& .image-container":{background:"rgba(0, 0, 0, 0.10)",borderRadius:8,marginRight:16,width:60,height:60,display:'flex',justifyContent:'center',alignItems:'center',overflow:'hidden'},
  "& .from-main-title-first":{color:"#000",fontFamily:"Corbel V2",fontSize:16,fontWeight:700,marginBottom:5},
  "& .from-main-title-second":{color:"#000",fontFamily:"Corbel V2",fontSize:14,fontWeight:400},
  "& .upload-icon-container":{background:"#F8FAFC",padding:10,borderRadius:6,position:"relative"},
  "& .main-input-label":{fontFamily:"Corbel V2",fontSize:16,fontWeight:400,color:"#334155"},
  "& .input-fiels-create":{background:"#FFFFFF",fontFamily:"Corbel V2",border:"1px solid #CBD5E1","&::placeholder":{fontFamily:"Corbel V2",color:"#334155"}},
  "& .error-message":{color:"#FF0000",fontFamily:"Corbel V2",},
  "& .input-fiels-create-textarea":{background:"#FFFFFF",fontFamily:"Corbel V2",border:"1px solid #CBD5E1",width:"100%",padding:10,borderRadius:8,resize:"none","&::placeholder":{fontFamily:"Corbel V2",color:"#334155"},"&:focus":{outline:"none"}},
  "& .price-field":{position:"relative"},
  "& .price-icon":{position:'absolute',top:13,right:12},
  "& .uploadBtnWrapper":{position:"relative",overflow:"hidden",display:"block","& input[type=file]":{position:"absolute",left:0,top:0,opacity:0,cursor:"pointer"},"& input":{position:"absolute",left:0,top:0,opacity:0}},
  "& .uploadBtn":{padding:10,borderRadius:5,border:"1px solid rgba(148,163,184,.5)",color:"#6c7487",width:"100%",textAlign:"left",background:"#FFFFFF",fontFamily:"Corbel V2",minHeight:50,display:"flex",alignItems:"center",justifyContent:"space-between",fontSize:14},
  "& .uploadBtn-upload":{padding:10,borderRadius:5,color:"#6c7487",width:"100%",textAlign:"left",background:"#FFFFFF",fontFamily:"Corbel V2",minHeight:50,display:"flex",alignItems:"center",justifyContent:"space-between",fontSize:14,boxShadow:"0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",border:'none'},
  "& .add-lesson-btn, .save-course-lesson-btn":{background:"#37657F",color:"#fff",borderRadius: 8,padding:"10px 16px",textTransform:'none',fontFamily:"Corbel V2",fontWeight:700,"& svg":{marginLeft:6,},"& span":{fontSize:16,}},
})

const ModalStyle = styled(Box)({
  zIndex:7,position:"fixed",top:"50%",left:"50%",transform:"translate(-50%, -50%)",width:"100%",
  "& .discardBtn":{minWidth:"100px",border:"1px solid #94A3B8",textTransform:'none',backgroundColor:"#F1F5F9",color:"#205e83",padding:"12px",width:"100%",fontWeight:700,lineHeight:"24px",maxWidth:"100px",fontFamily:"Corbel V2"},
  "& .modalFooter":{margin:"0",textAlign:"right",display:"flex",justifyContent:"flex-end",gridGap:"20px",gap:"20px",padding:"40px 0 0",flexWrap:"wrap"},
  "& .image-uploaded-box":{
    position: 'absolute',
    top: 0,
    height: '180px',
    opacity: 0,
    cursor:"pointer"
  },
  "& .discardBtn:hover":{color:"#fff",backgroundColor:"#205e83",},
  "& .display-none":{display:"none"},
  "& .MuiAlert-root":{marginTop:20,fontFamily:"Corbel V2"},
  "& .uploadBtn":{color:"#fff",maxWidth:" 150px",minWidth:"150px",fontSize:"16px",fontFamily:"Corbel V2",background:'#205e83',textTransform:"none",fontWeight:700,},
  "& .closeBtn":{background:"rgba(217, 217, 217, 0.50)",border:"none",fontSize:"24px",cursor:"pointer",padding:"0",width:"40px",minWidth:"40px",height:"40px",lineHeight:"35px",color:"#1C1B1F",borderRadius:"6px",},
  "& .modalDialog":{padding:"40px",maxWidth:"1280px",margin:"0 auto",background:"#fff",borderRadius:"10px","@media (max-width: 1366px)":{maxWidth:'1000px'},"@media (max-width: 1199px)":{maxWidth:'850px'},"@media (max-width: 991px)":{maxWidth:'650px'}},
  "& .modalHeader":{padding:'0px',display:"flex",alignItems:"baseline",justifyContent:"space-between","& span":{fontSize:'32px'}},
  "& .blockHeading":{padding:'0px',"& h3":{letterSpacing:"-0.198px",margin:"0 0 5px",color:"#334155",fontSize:"32px",fontStyle:"normal",fontWeight:700,lineHeight:"normal",display:"flex",alignItems:"center",},"& p":{color:"#37657F",fontSize:"16px",fontStyle:"normal",fontWeight:400,lineHeight:"normal",paddingTop:"5px",margin:"0",}},
  "& .modalBody":{position:"relative",padding:"40px 0",},
  "& .modalBody::after":{transform:"translate(-50%)",content:'""',position:"absolute",borderBottom:"1px solid #E2E8F0",bottom:"-25px",left:"50%",width:"100%",},
  "& .dargBlock":{position:"relative",border:"1px dashed #94A3B8",margin:"0",borderRadius:"5px",height:"180px",textAlign:"center",background:"#F8FAFC",cursor:"pointer",},
  "& .uploadBtnWrapper":{borderRadius:5,position:"relative",overflow:"hidden",display:"inline-block",width:"100%",height:"180px",lineHeight:"150px",},
  "& .dropButton":{border:"none",flexDirection:'column',color:"#334155",background:"transparent",padding:"0",textAlign:"center",height:"180px",fontSize:"16px",fontStyle:"normal",fontWeight:400,lineHeight:"normal",textTransform:'none',margin:'auto',display:'flex',alignItems:'center',justifyContent:'center',"& .sizeText":{fontSize:"14px",fontStyle:"normal",fontWeight:400,lineHeight:"normal",color:"#37657F",padding:"9px 0 0",display:"block",},"& span":{flexDirection:'column',}},
})

const ModalStyleMainContainer = styled(Dialog)({
  "& .MuiDialogContent-root, .MuiDialogContent-root:first-child":{ padding:0 }
})

const ModalStyleMain = styled(Box)({
  "& .title-two":{fontWeight:400,color:'#37657F',fontSize:'16px',},
  "& .first-container":{padding:40,},
  "& .Delete-icon-container":{width:'56px',height:'56px',justifyContent:'center',alignItems:'center',borderRadius:'10px',background:'#F1F5F9',margin:"24px 0",display:'flex',},
  "& .second-container":{borderTop:'1px solid #E2E8F0',display:'flex',gap:24,justifyContent:"flex-end",padding:'24px 40px',},
  "& .cancel-delete-button":{border:'1px solid #37657F',background:"#F1F5F9",color:"#37657F",width:'164px',height:'56px',textTransform:'none',fontFamily:"Corbel V2",fontWeight:700,borderRadius:'8px',"& span":{fontSize:16,},"& svg":{marginLeft:6,},},
  "& .delete-session-button":{borderRadius:'8px',background:"#DC2626",color:"#FFF",width:'164px',height:'56px',textTransform:'none',fontFamily:"Corbel V2",fontWeight:700,"& span":{fontSize:16,},"& svg":{marginLeft:6,},},
  "& .MuiTypography-root":{fontFamily:"Corbel V2",},
  "& .title-one":{fontWeight:700,color:'#37657F',fontSize:'24px',},
  "& .close-button-session":{cursor:"pointer",width:'40px',height:'40px',borderRadius:'6px',background:'rgba(217, 217, 217, 0.50)',display:'flex',alignItems:'center',justifyContent:'center',},
})

// Customizable Area End

