import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: any;
  pieChartData: any;
  userSessionData: any;
  firstChartData: any;
  searchTerm: string;
  participantAttendanceData: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsChartController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;
  apiGetUserSessionData: any;
  apiGetFirstChartData: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: null,
      pieChartData: [
        ["Status", "Users"],
        ["Attended", 0],
        ["Not Attended", 0],
      ],
      userSessionData: [],
      firstChartData: [],
      searchTerm: '',
      participantAttendanceData: [
        {
          name: "Theresya Monna",
          time: "10:00PM to 11:00PM",
          date: "10 May 2023",
          attended: "YES",
        },
        {
          name: "Jenifer Ardy",
          time: "08:00PM to 10:00PM",
          date: "09 May 2023",
          attended: "NO",
        },
        {
          name: "Jong Dae",
          time: "06:00PM to 07:00PM",
          date: "08 May 2023",
          attended: "YES",
        },
        {
          name: "Theresya Monna",
          time: "05:00PM to 07:00PM",
          date: "07 May 2023",
          attended: "NO",
        },
        {
          name: "Theresya Monna",
          time: "08:00PM to 09:00PM",
          date: "06 May 2023",
          attended: "YES",
        },
        {
          name: "Theresya Monna",
          time: "08:00AM to 10:00AM",
          date: "05 May 2023",
          attended: "YES",
        },
      ],
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleSearchChange = this.handleSearchChange.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      apiRequestCallId === this.apiGetDataCallId &&
        responseJson &&
        this.reciveDataForPieChart(responseJson);
      apiRequestCallId === this.apiGetUserSessionData &&
        responseJson &&
        this.reciveDataForUserSession(responseJson);
      apiRequestCallId === this.apiGetFirstChartData &&
        responseJson &&
        this.reciveDataForFirstChart(responseJson);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();

    // Customizable Area Start

    // Customizable Area End
  }

  getToken = () => {
    this.setState({ token: localStorage.getItem("token") }, () => {
      this.getPieChartData(null);
      this.getUserSessionsData(null);
      this.getFirstChartData(null);
    });
  };

  // Customizable Area Start

  handleSearchChange(event: any) {
    this.setState({ searchTerm: event.target.value });
  }

  navigateToHomePage = () => {
    this.props.navigation.navigate("MyCoursesLecturer");
  };

  reciveDataForPieChart = (responseJson: any) => {
    this.setState({
      pieChartData: [
        ["Status", "Users"],
        ["Attended", responseJson.total_participants_participated],
        [
          "Not Attended",
          Math.abs(responseJson.total_participants_not_attended),
        ],
      ],
    });
  };

  reciveDataForUserSession = (responseJson: any) => {
    this.setState({
      userSessionData: responseJson,
    });
  };

  reciveDataForFirstChart = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({
        firstChartData: null,
      });
    } else {
      this.setState({
        firstChartData: [
          ["Date", "Regular Users", "New Users"],
          ...responseJson.session_data.map(
            (session: {
              session_date: string | number | Date;
              old_users: any;
              new_users: any;
            }) => {
              return [
                session.session_date,
                session.old_users,
                session.new_users,
              ];
            }
          ),
        ],
      });
    }
    console.log(this.state.firstChartData);
  };

  getPieChartData = async (selectDate: any) => {
    let token = this.state.token;
    let courseId = Number(localStorage.getItem("sessionReportingId"));
    let date = "";
    if (selectDate) {
      date = `&start_date=${selectDate.date}&end_date=${selectDate.endTime}`;
    }

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sessionReportingZoomApiEndPoint +
        `?course_id=${courseId}${date}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUserSessionsData = async (selectDate: any) => {
    let token = this.state.token;
    let courseId = Number(localStorage.getItem("sessionReportingId"));
    let date = "";
    if (selectDate) {
      date = `&start_date=${selectDate.date}&end_date=${selectDate.endTime}`;
    }

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserSessionData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userSessionsZoomApiEndPoint + `?course_id=${courseId}${date}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFirstChartData = async (selectDate: any) => {
    let token = this.state.token;
    let courseId = Number(localStorage.getItem("sessionReportingId"));
    let date = "";
    if (selectDate) {
      date = `&start_date=${selectDate.date}&end_date=${selectDate.endTime}`;
    }

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetFirstChartData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.firstChartZoomApiEndPoint + `?course_id=${courseId}${date}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onSubmit = async (values: any) => {
    this.getPieChartData(values);
  };

  onSubmitUserSessionDate = async (values: any) => {
    this.getUserSessionsData(values);
  };

  onSubmitFirstChartDate = async (values: any) => {
    this.getFirstChartData(values);
  };
  // Customizable Area End
}
